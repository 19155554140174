<template>
    <section class="content-wrapper">
      <pageLoader v-show="ploader" :key="key"/>
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-12">
                              <div class="row">
                                <!-- liste des articles -->
                                <div class="col-md-6 col-sm-12 grid-margin stretch-card"
                                v-for="(post,postId) in posts" :key="postId"
                                >
                                  <div class="card">

                                    <img v-if="!post.image.content" class="card-img-top" src="@/assets/images/dashboard/img_1.jpg" alt="card images">
                                    <picture v-else slot="img">
                                        <source :srcset="post.image.content.webpImage"  type="image/webp">
                                        <img class="img-fluid" :src="post.image.content.originalImage" alt="image du blog"/>
                                    </picture>

                                    <div class="card-body pb-0">
                                      <!-- <p class="text-muted">RAGNAROCK- Museet for pop, Roskilde, Denmark</p> -->
                                      <h4 class="pointer" @click="goToPost(post.ulid)">{{ post.title }}</h4>
                                      <div class="d-flex align-items-center justify-content-between text-muted border-top py-3 mt-3">
                                     
                                        <p class="mb-0">Publié le {{post.image.datepublished}}</p> <!--date tsy izy-->
                                        <div class="wrapper d-flex align-items-center">
                                          <small class="mr-2">93</small>
                                          <i class="mdi mdi-heart-outline"></i>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import axios from 'axios'
export default {
    name:'news',
    data(){
      return {
        posts:[],
        ploader:true,
        key:1
      }
    },
    methods:{
      getPosts(){
        axios.get(`posts`)
        .then(resPosts => {
          this.posts = resPosts.data.result
          console.log("tonga le posts");
          this.ploader = false
          this.key++
          console.log("tonga le ploader");
        })
        .catch(errPosts => {
          console.error({errPosts}); 
        })
      },
      goToPost(ulid){
        this.$router.push({
          name:'blog',
          params:{
            ulid: ulid
          }
        })
      }
    },
    mounted(){
      this.getPosts()
    }
}
</script>

<style scoped>
</style>